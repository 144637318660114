// extracted by mini-css-extract-plugin
export var mover = "text-module--mover--g5csY";
export var mover2 = "text-module--mover2--3aMww";
export var rotation = "text-module--rotation--yOwJy";
export var textAnimated = "text-module--text-animated--5GTHr";
export var textCard = "text-module--text-card--ba6pA";
export var textContainer = "text-module--text-container--oTz1X";
export var textImage = "text-module--text-image--C5NBn";
export var textImage3 = "text-module--text-image3--GWsBO";
export var textImage4 = "text-module--text-image4--n3oSx";
export var textImage5 = "text-module--text-image5--0yFsy";
export var textImage6 = "text-module--text-image6--8-ZQC";
export var textMain = "text-module--text-main--J3bK2";
export var textSub = "text-module--text-sub--oU7hk";